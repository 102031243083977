import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import useAnonymousMode from '../iframe/useAnonymousMode';
import { chatActions } from '../../lib/redux/chat/slice';

export default function useGuidedSuggestedPrompts() {
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  const anonymous = useAnonymousMode();

  const loadGuidedPrompts = async () => {
    if (anonymous || loading) {
      return;
    }
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/${sessionId}/guided-prompts/`;
    setLoading(true);
    const response = await api.get(url);
    setLoading(false);
    if (response?.data) {
      const prompts = response.data?.ai_prompts;
      if (prompts?.length > 0) {
        setGuidedPrompts(prompts);
      } else {
        const retry = await api.get(url);
        if (retry?.data?.ai_prompts?.length > 0) {
          setGuidedPrompts(retry.data.ai_prompts);
        }
      }
    }
  };

  const setGuidedPrompts = (prompts) => {
    dispatch(chatActions.guidedPromptsUpdated(prompts));
  };

  const handleReloadGuidedQuestionsBtnClick = () => {
    loadGuidedPrompts();
  };

  return { handleReloadGuidedQuestionsBtnClick, loadGuidedPrompts };
}
