import React from 'react';
import './Featuredmentor.css';
import useActiveMentor from '../../hooks/api/mentors/useActiveMentor';
import useMentorSlug from '../../hooks/api/mentors/useMentorSlug';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { useDebounce } from '../../hooks/useDebounce';
import { textTruncate } from '../../utils/shared';

const Featuredmentor = ({ mentor }) => {
  const auth = useSelector((state) => state.auth.data);
  const { active, handleMentorBoxClick } = useActiveMentor(mentor);
  const slug = useMentorSlug(mentor);
  let profileImageUrl = mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }

  const handleFeaturedMentorHover = () => {
    $('.ai-expert-element-block').on('mouseover', function () {
      const topPosition = $(this)
        .find('.ai-expert-desc-container')
        .offset().top;
      const width = $(this).width();
      $(this)
        .find('.featured-mentor-tooltip')
        .css({
          //position: "fixed",
          top: `${topPosition}px`,
          display: 'flex',
          width: `${width}px`,
        });
    });

    $('.ai-expert-element-block').on('mouseout', function () {
      $(this).find('.featured-mentor-tooltip').css({ display: 'none' });
    });
  };

  useDebounce(handleFeaturedMentorHover, 200, []);

  return (
    <div
      className={`w-layout-hflex ai-expert-element-block light-grey-bg-dark-mode greyish-bg-in-darkmode ${active ? 'w--current ai-expert-element-is-active' : ''}`}
      map=""
      map-value="mentor"
      prop-events-names="onClick"
      onClick={handleMentorBoxClick}
      prop-events-value-onclick="handleMentorBoxClick"
    >
      <div className="w-layout-vflex ai-expert-element-top-area">
        <div
          className="w-layout-hflex expert-profile-container cloud-computer"
          style={{
            backgroundImage: `url(${profileImageUrl ?? '/images/default_avatar.webp'})`,
          }}
        ></div>
        <div className="w-layout-vflex ai-expert-desc-block">
          <h5 className="ai-expert-title">{mentor.name}</h5>
          <div className="w-layout-hflex ai-expert-desc-container">
            <div title={mentor.description} className="ai-expert-desc">
              {textTruncate(mentor.description, 60)}
            </div>
          </div>
          <div className="w-layout-hflex featured-mentor-tooltip light-grey-bg-dark-mode greyish-bg-in-darkmode">
            <div className="featured-mentor-tooltip-text">
              {mentor.description}
            </div>
          </div>
        </div>
      </div>
      <div className="w-layout-hflex ai-expert-element-bottom-area all-whiten-in-dark-mode">
        <div className="ai-expert-element-username">{slug}</div>
        <div className="w-layout-hflex ai-expert-element-stat-block">
          <img
            alt=""
            className="ai-expert-element-stat-icon"
            loading="lazy"
            src="images/bar-chart.svg"
          />
          <div className="ai-expert-element-stat-label">{mentor.llm_name}</div>
        </div>
      </div>
    </div>
  );
};

export default Featuredmentor;
