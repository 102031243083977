import React from 'react';
import './Defaultsuggestedprompttext.css';
import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';

const Defaultsuggestedprompttext = (props) => {
  const mentors = useSelector((state) => state.mentors);
  const defaultPrompt =
    'Can you help me write a research paper on the history of the Roman Empire?';

  const prompt = mentors?.mentor?.settings?.suggested_message ?? defaultPrompt;

  const { handleSuggestedPromptSelection } = useSuggestedPrompt();

  return (
    <div
      className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode"
      map=""
      onClick={() => handleSuggestedPromptSelection(prompt)}
      map-value="label"
    >
      <div className="default-suggestion-prompt">{prompt}</div>
    </div>
  );
};

export default Defaultsuggestedprompttext;
