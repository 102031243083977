import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useMentors from './useMentors';
import { useTriggers } from '../../navigation';
import { toast } from 'react-toastify';
import { authActions } from '../../../lib/redux/auth/slice';
import useApi from '../base/useApi';

export default function useSetCurrentMentor(start) {
  const [featured, setFeatured] = useState(true);
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const { loading, error } = useMentors(featured, start);
  const api = useApi();

  const { _handleUpdateModalStatus } = useTriggers();
  const urlParams = new URLSearchParams(window.location.search);
  const requestedMentor = urlParams.get('mentor');

  const _setCurrentMentor = (mentor) => {
    dispatch(mentorActions.currentMentorUpdated(mentor));
  };

  const _getDefaultMentorFromList = (mentors) => {
    return mentors?.find((item) => item?.metadata?.default) || mentors[0];
  };

  const loadMentorPublicSettings = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${requestedMentor}/public-settings/`;
    const response = await api.get(url);
    if (response?.data) {
      const settings = response.data;
      dispatch(
        mentorActions.currentMentorUpdated({
          name: requestedMentor,
          slug: requestedMentor,
          suggested_prompts: settings?.suggested_prompts,
          settings,
        })
      );
    } else {
      toast.error(`Could not load the requested mentor: ${requestedMentor}`);
    }
  };

  useEffect(() => {
    if (requestedMentor && !mentors?.mentor && auth?.tenant && auth?.user) {
      loadMentorPublicSettings();
    }
  }, [auth]);

  useEffect(() => {
    if (mentors?.featured?.results?.length === 0 && featured) {
      setFeatured(false);
    }
  }, [mentors?.featured]);

  useEffect(() => {
    if (mentors?.mentors?.results?.length === 0) {
      if (auth?.tenant?.is_admin) {
        dispatch(mentorActions.initialSetupUpdated(true));
        _handleUpdateModalStatus('mentor-creation-modal', true);
        toast.info("You don't have any mentors. Please create one");
      } else {
        toast.info(
          "You don't yet have any mentors to chat with. Please reach out to your Admin."
        );
      }
    }
  }, [mentors?.mentors]);

  useEffect(() => {
    if (!mentors?.mentor) {
      if (mentors?.featured?.results?.length > 0 && !requestedMentor) {
        _setCurrentMentor(
          _getDefaultMentorFromList(mentors?.featured?.results)
        );
      } else if (mentors?.mentors?.result?.length > 0 && !requestedMentor) {
        _setCurrentMentor(mentors?.mentors?.results[0]);
      }
    }
  }, [mentors]);

  return { loading, error };
}
