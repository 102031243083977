import React from 'react';
import './Dataseturlresourceuploadmodalbox.css';
import useDatasetResourceAdd from '../../hooks/datasets/useDatasetResourceAdd';
import { useTriggers } from '../../hooks/navigation';

const Dataseturlresourceuploadmodalbox = (props) => {
  const {
    dataset,
    handleSubmit,
    handleUrlChange,
    handleFileChange,
    submitting,
  } = useDatasetResourceAdd();
  const { handleModalCloseClick } = useTriggers();
  return (
    <div className="modal dataset-url-resource-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{dataset?.type}</h3>
          <div
            className="modal-close-wrapper"
            onClick={handleModalCloseClick}
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="80a8acea-99b7-6d2f-d101-79f95257134c"
              data-wf-page-id="650305782bc61751e5765214"
              id="dataset-document-form"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    {dataset?.type}
                  </label>
                </div>
                {['URL', 'YouTube'].includes(dataset?.type) ? (
                  <input
                    className="form-input secret-key-form-input w-input"
                    custom-value="Personal"
                    data-name="url"
                    disabled={submitting}
                    id="dataset-url"
                    maxLength={256}
                    name=""
                    onChange={handleUrlChange}
                    placeholder="URL"
                    type="text"
                  />
                ) : (
                  <input
                    onChange={handleFileChange}
                    type="file"
                    name="file"
                    disabled={submitting}
                    id="dataset-file-input"
                  />
                )}
              </div>
              <div className="w-layout-hflex flex-block-20">
                <a
                  className="default-btn form-submit-btn invite-user-submit-btn w-button"
                  data-w-id="80a8acea-99b7-6d2f-d101-79f952571355"
                  onClick={handleSubmit}
                  href="#"
                >
                  {submitting ? (
                    <span>Please wait...</span>
                  ) : (
                    <span>Submit</span>
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dataseturlresourceuploadmodalbox;
