import { copyTextToClipBoard } from '../../../utils/shared';
import { toast } from 'react-toastify';
import useSuggestedPrompt from '../../chat-room/useSuggestedPrompt';
import { useTriggers } from '../../navigation';
import { useDispatch } from 'react-redux';
import { promptActions } from '../../../lib/redux/prompts/slice';

export default function usePromptItem(prompt) {
  const dispatch = useDispatch();
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { _handleUpdateModalStatus, handleOpenPromptDetailModal } =
    useTriggers();
  const handleCopyPrompt = () => {
    copyTextToClipBoard(prompt?.description);
    toast.success('Copied!');
  };
  const handleShowPromptDetail = () => {
    handleOpenPromptDetailModal();
    dispatch(promptActions.selectedPromptUpdated(prompt));
  };

  const handleTriggerPrompt = () => {
    handleSuggestedPromptSelection(prompt.description);
    setTimeout(() => {
      _handleUpdateModalStatus('prompt-gallery-modal', false);
    }, 500);
  };

  return {
    handleCopyPrompt,
    handleTriggerPrompt,
    handleShowPromptDetail,
  };
}
