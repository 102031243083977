import React from 'react';
import './Learnmorelink.css';

const Learnmorelink = (props) => {
  const source = props.source;
  return (
    <>
      {source?.status === 'show' && (
        <a
          className="learn-more-link w-inline-block"
          href={source.url}
          map=""
          map-value="link"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-block-37">{source.url}</div>
        </a>
      )}

      {source?.status === 'disable' && (
        <div className="learn-more-link w-inline-block">
          <div className="text-block-37">{source.url}</div>
        </div>
      )}
    </>
  );
};

export default Learnmorelink;
