export default function PromptMailToShare({ prompt, children }) {
  const subject = 'Check out my prompt :sunglasses:';
  const body = `Hey! Wanted to share my prompt on ${process.env.REACT_APP_IBL_MENTOR_URL}\n ${prompt.description}`;
  return (
    <a
      className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
      href={`mailto:?subject=${subject}&body=${body}`}
      style={{ textDecoration: 'none', color: '#3a3a3a' }}
    >
      {children}
    </a>
  );
}
