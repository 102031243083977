import Llmbox from '../../components/Llmbox/Llmbox';
import Loader from '../loader';
import React from 'react';
import { useSelector } from 'react-redux';
import useLLMs from '../../hooks/llm/useLLMs';

export default function LLMs({ showSelected }) {
  const mentors = useSelector((state) => state.mentors);
  const editMentor = useSelector((state) => state.mentors.edit);
  const { modals } = useSelector((state) => state.navigation.data);

  // Move the selected LLM to the top of list
  const selectedLLM = mentors?.llms?.find(
    (llm) => editMentor?.mentor?.llm_name === llm.model
  );
  const llms = mentors?.llms?.filter(
    (llm) => editMentor?.mentor?.llm_name !== llm.model
  );

  if (selectedLLM) {
    llms.unshift(selectedLLM);
  }

  const { loading, error } = useLLMs();
  return (
    <>
      <div
        className={`w-layout-grid llm-grid-block ${modals?.['edit-mentor-modal'] ? 'edit-mentor-llm-grid-block' : ''} `}
      >
        {llms?.map((llm) => (
          <Llmbox showSelected={showSelected} key={llm?.model} llm={llm} />
        ))}
      </div>
      {loading && <Loader />}
    </>
  );
}
