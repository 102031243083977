import Learnmorelink from '../Learnmorelink/Learnmorelink';
import React from 'react';
import './Aireponsebox.css';
import useAiResponseSources from '../../hooks/chat-room/useAiResponseSources';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import { copyTextToClipBoard } from '../../utils/shared';
import { toast } from 'react-toastify';
import AIResponseRating from '../../modules/chat-room/upper-area/response-rating';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';

const Aireponsebox = (props) => {
  const { sources } = useAiResponseSources(props.userQuery, props.streaming);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const anonymous = useAnonymousMode();
  return (
    <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode learn-more-container">
      <div className="w-layout-hflex ai-response-container-content">
        <div className="ai-gif-container">
          <img
            alt=""
            className="image-3"
            loading="lazy"
            src="/images/academy.gif"
          />
        </div>
        <div
          className="ai-response-text-container"
          dangerouslySetInnerHTML={{ __html: props?.message?.content }}
        ></div>
      </div>
      {!props?.message?.share && (
        <div className="w-layout-vflex learn-more-block">
          {sources?.length > 0 && (
            <div className="w-layout-hflex learn-more-container all-whiten-in-dark-mode">
              <h5 className="heading-10">Learn more :</h5>
              {sources?.map((item) => (
                <Learnmorelink key={item.url} source={item} />
              ))}
            </div>
          )}
          <div className="w-layout-hflex rating-copy-retry-block">
            {!anonymous && (
              <AIResponseRating userMsg={props.userQuery} msg={props.message} />
            )}
            <div className="w-layout-hflex copy-retry-container all-whiten-in-dark-mode">
              <a
                className="response-action-link copy-action-btn w-inline-block"
                href="#"
                onClick={() => {
                  copyTextToClipBoard(props.userQuery?.content);
                  toast.success('Copied');
                }}
                prop-events-value-onclick="handleCopyResponseBtnClick"
              >
                <img
                  alt=""
                  className="response-action-icon"
                  loading="lazy"
                  src="images/clipboard.svg"
                />
                <div className="response-action-label">Copy</div>
              </a>
              <a
                className="response-action-link retry-action-btn w-inline-block"
                href="#"
                onClick={() => {
                  handleSuggestedPromptSelection(props.userQuery?.content);
                }}
                prop-events-value-onclick="handleRetryResponseBtnClick"
              >
                <img
                  alt=""
                  className="response-action-icon"
                  loading="lazy"
                  src="images/rotate-ccw.svg"
                />
                <div className="response-action-label">Retry</div>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Aireponsebox;
