import React from 'react';
import './Promptgallerydetailmodalbox.css';
import usePromptItem from '../../hooks/api/prompts/usePromptItem';
import { useSelector } from 'react-redux';
import { useTriggers } from '../../hooks/navigation';
import PromptMailToShare from '../../modules/prompts/prompt-mail-share';
import useEditPrompt from '../../hooks/api/prompts/useEditPrompt';
import Loader from '../../modules/loader';

const Promptgallerydetailmodalbox = (props) => {
  const prompt = useSelector((state) => state.prompts.selectedPrompt);
  const { handleModalCloseClick } = useTriggers();
  const { handleCopyPrompt, handleTriggerPrompt } = usePromptItem(prompt);
  const { openEdit, handleChange, handleOpenEdit, handleSave, saving } =
    useEditPrompt(prompt);
  return (
    <div className="modal prompt-gallery-element-view-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container prompt-gallery-element-view-modal-container">
        <div className="w-layout-hflex modal-header">
          {openEdit && !prompt?.isSystem && !prompt.index ? (
            <div className="prompt-gallery-element-view-header-form-block w-form">
              <form
                className="prompt-gallery-element-view-header-form"
                data-name="Email Form 9"
                data-wf-element-id="3e513de2-73a7-4df0-fdd0-5a9667551a0d"
                data-wf-page-id="650305782bc61751e5765214"
                id="email-form-9"
                method="get"
                name="email-form-9"
              >
                <input
                  className="prompt-gallery-element-view-header-form-input w-input"
                  data-name="Email"
                  id="email"
                  disabled={saving}
                  defaultValue={prompt.prompt}
                  onChange={handleChange}
                  maxLength={256}
                  name="prompt"
                  placeholder=""
                />
              </form>
            </div>
          ) : (
            <div className="modal-header-title prompt-gallery-element-view-modal-header-title">
              Define the purpose of your mentor.
            </div>
          )}
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body prompt-gallery-element-view-modal-body">
          {!openEdit && (
            <p className="prompt-gallery-element-desc-parag">
              {prompt?.description}
            </p>
          )}
          {openEdit && (
            <div className="prompt-gallery-element-view-desc-form-block w-form">
              <form
                className="prompt-gallery-element-view-desc-form"
                data-name="Email Form 10"
                data-wf-element-id="1f4a83bc-a16c-f78f-8d36-5b581f17207b"
                data-wf-page-id="650305782bc61751e5765214"
                id="email-form-10"
                method="get"
                name="email-form-10"
              >
                <textarea
                  className="prompt-gallery-element-view-desc-form-textarea w-input"
                  data-name="Field 7"
                  defaultValue={prompt?.description}
                  id="field-7"
                  maxLength={5000}
                  rows="8"
                  disabled={saving}
                  onChange={handleChange}
                  name="description"
                  placeholder="Write prompt"
                ></textarea>
              </form>
            </div>
          )}
          <div className="w-layout-hflex flex-block-69">
            {openEdit && (
              <div
                onClick={handleSave}
                className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn prompt-gallery-element-save-btn"
              >
                <img
                  alt=""
                  className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                  loading="lazy"
                  src="images/save-3.svg"
                />
                <div>Save</div>
              </div>
            )}

            {!openEdit && (
              <div
                onClick={handleOpenEdit}
                className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn prompt-gallery-element-edit-btn"
              >
                <img
                  alt=""
                  className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                  loading="lazy"
                  src="images/pencil.svg"
                />
                <div>Edit</div>
              </div>
            )}
            <div
              onClick={handleTriggerPrompt}
              className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
            >
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="images/terminal.svg"
              />
              <div>Prompt</div>
            </div>
            <div
              onClick={handleCopyPrompt}
              className="w-layout-hflex gallery-element-btn prompt-gallery-element-btn"
            >
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="images/copy.svg"
              />
              <div>Copy</div>
            </div>
            <PromptMailToShare prompt={prompt}>
              <img
                alt=""
                className="gallery-element-btn-icon gallery-element-btn-showed-icon"
                loading="lazy"
                src="images/share-2.svg"
              />
              Share
            </PromptMailToShare>
          </div>

          {saving && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Promptgallerydetailmodalbox;
