import { useDispatch, useSelector } from 'react-redux';
import useAxdApi from '../base/useAxdApi';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useApi from '../base/useApi';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import useFeaturedMentors from './useFeaturedMentors';
import { useTriggers } from '../../navigation';

const useMentors = (featured = false, start = true) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({ page_size: 10 });

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  let endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/`;
  const api = useApi();
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { handleAddMentorToFeatured } = useFeaturedMentors();
  const { handleOpenEditMentorModal } = useTriggers();

  const loadMentors = async () => {
    const params = { ...filters };
    if (featured) {
      params.featured = true;
    }

    setLoading(true);
    const response = await api.get(endpoint, params);
    if (response?.data) {
      const data = response?.data;
      if (featured) {
        dispatch(
          mentorActions.featuredMentorsUpdated({
            ...data,
            page: filters?.page ?? 1,
          })
        );
      } else {
        dispatch(
          mentorActions.mentorsUpdated({
            ...data,
            page: filters?.page ?? 1,
          })
        );
      }
    } else {
      setError(response?.error);
    }

    setLoading(false);
  };

  const handleNextPage = (currentPage) => {
    setFilters((prevState) => {
      return { ...prevState, page: currentPage + 1 };
    });
  };

  const handlePreviousPage = (currentPage) => {
    setFilters((prevState) => {
      return { ...prevState, page: currentPage - 1 };
    });
  };

  const handleMentorItemSelect = (mentor) => {
    dispatch(mentorActions.editMentorUpdated(mentor));
    handleOpenEditMentorModal();
  };

  const updateLocalMentor = (mentor) => {
    const newMentors = mentors?.mentors?.results?.map((item) => {
      if (item.name === mentor.name) {
        return mentor;
      } else {
        return item;
      }
    });
    dispatch(
      mentorActions.mentorsUpdated({ ...mentors?.mentors, results: newMentors })
    );
  };

  const addMentorToFeatured = (mentor) => {
    const newFeaturedMentors = {
      ...(mentors?.featured ?? {}),
      results: [...(mentors?.featured?.results ?? []), mentor],
    };
    dispatch(mentorActions.featuredMentorsUpdated(newFeaturedMentors));
  };

  const removeMentorFromFeatured = (mentor) => {
    const newFeaturedMentors = mentors?.featured?.results?.filter(
      (item) => item.name !== mentor.name
    );
    dispatch(mentorActions.featuredMentorsUpdated(newFeaturedMentors));
  };

  const handleFeaturedStateChanged = async (mentor, status) => {
    const payload = {
      featured: status,
    };
    await handleUpdateSettings(mentor, payload);
    const featuredMentor = {
      ...mentor,
      metadata: { ...mentor.metadata, featured: status },
    };
    updateLocalMentor(featuredMentor);
    if (status) {
      addMentorToFeatured(mentor);
    } else {
      removeMentorFromFeatured(mentor);
    }
    handleAddMentorToFeatured(featuredMentor);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  let mentorsToDisplay = mentors?.mentors?.results?.filter((item) =>
    item.name?.toLowerCase().includes(search?.toLowerCase())
  );
  if (!search) {
    mentorsToDisplay = mentors?.mentors?.results;
  }

  useEffect(() => {
    if (filters?.page) {
      loadMentors();
    }
  }, [filters]);

  useEffect(() => {
    if (
      start &&
      !loading &&
      ((!mentors?.mentors && !featured) || (!mentors?.featured && featured))
    ) {
      loadMentors();
    }
  }, [featured, filters, start]);

  return {
    loading,
    error,
    page: filters?.page || 1,
    handleNextPage,
    handlePreviousPage,
    handleSearchChange,
    handleFeaturedStateChanged,
    mentorsToDisplay,
    handleMentorItemSelect,
  };
};
export default useMentors;
