import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDataTable, exportTableToExcel } from '../../../utils/shared';
import { chatActions } from '../../../lib/redux/chat/slice';
import { marked } from 'marked';
import { BASE_API_URL } from '../../../utils/api';
import { navigationActions } from '../../../lib/redux/navigation/slice';

export default function useSessionMessageItem(item, type) {
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuTrigger, setShowMenuTrigger] = useState(false);
  const messageMenuRef = useRef(null);
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);
  const nav = useSelector((state) => state.navigation.data);

  const dispatch = useDispatch();

  const message = item?.messages?.find(
    (msg) =>
      msg?.message?.type === 'human' &&
      msg?.message?.data?.content.trim() !== ''
  );

  const handleDeleteSession = async () => {
    const url = `${BASE_API_URL}/api/ai-mentor/orgs/${auth?.tenant.key}/users/${auth?.user?.user_nicename}/sessions/${item.session_id}/`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('axd_token')}`,
        },
      });
      if (response.ok) {
        if (type !== 'pinned') {
          const newRecentMessages = chat?.recentMessages?.results?.filter(
            (session) => session.session_id !== item.session_id
          );
          dispatch(
            chatActions.recentMessagesUpdated({
              ...chat?.recentMessages,
              results: newRecentMessages,
            })
          );
        } else {
          const newPinMessages = chat?.pinMessages?.filter(
            (session) => session.session_id !== item.session_id
          );
          dispatch(
            chatActions.pinMessagesUpdated({
              ...chat?.pinMessages,
              results: newPinMessages,
            })
          );
        }

        setShowMenu(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePinMessage = async () => {
    const url = `${BASE_API_URL}/api/ai-mentor/orgs/${auth?.tenant.key}/users/${auth?.user?.user_nicename}/pin-message/`;
    const payload = {
      username: auth?.user?.user_nicename,
      session_id: item.session_id,
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Token ${localStorage.getItem('axd_token')}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setShowMenu(false);
        const newRecentMessages = chat?.recentMessages?.results?.filter(
          (session) => session.session_id !== item.session_id
        );
        dispatch(
          chatActions.recentMessagesUpdated({
            ...chat?.recentMessages,
            results: newRecentMessages,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMessageExport = () => {
    let messages = item.messages.filter((item) => item?.message?.data?.content);
    if (type === 'pinned') {
      messages = [...messages].reverse();
    }
    createDataTable(messages);
    exportTableToExcel('export-message-table');
    setShowMenu(false);
  };

  const handleChatBoxMenuClick = () => {
    setShowMenu(true);
  };

  function handleClickOutsideMenu(event) {
    if (
      showMenu &&
      messageMenuRef.current &&
      !messageMenuRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  }

  const handleSessionSelected = () => {
    let messages = item.messages;
    messages = [...messages].reverse();

    const msgToDisplay = messages?.map((msg) => {
      return {
        type: msg?.message?.type,
        content: msg?.message?.data?.content
          ? marked.parse(msg?.message?.data?.content)
          : '',
        rawContent: msg?.message?.data?.content,
      };
    });
    dispatch(
      chatActions.chatUpdated({
        ...chat.data,
        sessionId: item.session_id,
      })
    );
    dispatch(chatActions.messagesUpdated(msgToDisplay));
    dispatch(navigationActions.navigationUpdated({ ...nav, sidebar: true }));
  };

  const handleUnpinMessage = async () => {};

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [handleClickOutsideMenu]);

  return {
    message,
    handleSessionSelected,
    showMenu,
    showMenuTrigger,
    setShowMenuTrigger,
    setShowMenu,
    handleChatBoxMenuClick,
    messageMenuRef,
    handlePinMessage,
    handleUnpinMessage,
    handleMessageExport,
    handleDeleteSession,
  };
}
