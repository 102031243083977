import { mentorIsIframe } from './shared';
import api from '../utils/api';

export const redirectToAuth = (enforceLogin = false) => {
  if (mentorIsIframe()) {
    return;
  }

  let authRedirectUrl = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?app=mentor`;
  if (enforceLogin && !mentorIsIframe()) {
    authRedirectUrl += '&enforce-login=1';
  }

  authRedirectUrl += `&redirect-to=${window.location.origin}`;

  window.location.href = authRedirectUrl;
};

export const completeAuthFromToken = (token) => {
  const tokenData = api.iblutils.parseJwt(token || '');
  localStorage.setItem('token', token);
  const axd_token = tokenData.data.axd_token;
  const axd_token_expires = tokenData.data.axd_token_expires;

  localStorage.setItem('axd_token', axd_token);
  localStorage.setItem('axd_token_expires', axd_token_expires);
  localStorage.setItem('userData', JSON.stringify(tokenData.data.user));
  localStorage.setItem('ibl_axd_url', tokenData.data.ibl_axd_url);
  localStorage.setItem('ibl_web_url', tokenData.data.ibl_web_url);
  localStorage.setItem('ibl_edx_url', tokenData.data.ibl_edx_url);
  localStorage.setItem('ibl_search_url', tokenData.data.ibl_search_url);
  localStorage.setItem(
    'current_tenant',
    JSON.stringify(tokenData.data.current_tenant)
  );
  localStorage.setItem('tenants', JSON.stringify(tokenData.data.tenants));
  localStorage.setItem('tenant', tokenData.data.current_tenant.key);
};
