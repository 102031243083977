import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router-dom';
import { createContext, useEffect, useRef, useState } from 'react';
import {
  isJSON,
  Logout,
  SsoLogin,
  AppDataContext,
  GLOBAL_COLORS,
  getUserDataFromCookie,
  clearCookies,
  useDashboard,
} from '@iblai/ibl-web-react-common';
import api from './utils/api';
import { createGlobalStyle } from 'styled-components';
import '@iblai/ibl-web-react-common/dist/index.css';

import IndexView from './views/IndexView';

import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import { store } from './lib/redux/store';
import LoggedOut from './modules/logged-out';
import { redirectToAuth } from './utils/auth';
import Login from './modules/login';
import useAnonymousMode from './hooks/iframe/useAnonymousMode';
import ChromeExtSSOLogin from './modules/chrome-extension/chrome-ext-sso-login';
import ChromeExtSSOLoginComplete from './modules/chrome-extension/chrome-ext-sso-login-complete';
import { mentorIsIframe } from './utils/shared';
import CheckoutSuccess from './modules/checkout/success';
import CheckoutCancel from './modules/checkout/cancel';

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    }
  }
};

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
    userInContext: '',
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData);
  const anomymous = useAnonymousMode();

  const authCheckFn = (isAdmin, isLoggedIn, appData) => {
    const currentTenantData = isJSON(appData.current_tenant)
      ? JSON.parse(appData.current_tenant)
      : appData?.current_tenant;
    return () => {
      if (anomymous || mentorIsIframe()) {
        return [];
      }

      if (isAdmin && currentTenantData && !currentTenantData?.is_admin) {
        throw new Response('Not Admin', { status: 403 });
      }
      if (isAdmin && !currentTenantData) {
        redirectToAuth();
      }

      if (isLoggedIn && (!appData?.axd_token || !appData?.dm_token)) {
        redirectToAuth();
        return;
      }
      return [];
    };
  };

  return (
    <>
      <GlobalStyles />
      <ReduxProvider store={store}>
        <AppDataContext.Provider
          value={{ appData: { ...appData }, setAppData }}
        >
          <RouterProvider
            router={createBrowserRouter([
              {
                path: '/',
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: '/',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: '/index.html',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },

                  {
                    path: '/share/chat/:sessionId',
                    element: <IndexView />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'sso-login',
                    element: <SsoLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'chrome-ext-sso-login',
                    element: <ChromeExtSSOLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'chrome-ext-login-complete',
                    element: <ChromeExtSSOLoginComplete />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/success',
                    element: <CheckoutSuccess appData={appData} />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/cancel',
                    element: <CheckoutCancel />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logged-out',
                    element: <LoggedOut />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logout',
                    element: <Logout />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'login',
                    element: <Login />,
                    loader: authCheckFn(false, false, appData),
                  },
                ],
              },
            ])}
          />
        </AppDataContext.Provider>
      </ReduxProvider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

export default App;
