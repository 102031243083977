import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { chatActions } from '../../../lib/redux/chat/slice';
import useApi from '../base/useApi';
import { BASE_API_URL } from '../../../utils/api';

export default function useRecentMessages() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const recentMessages = useSelector((state) => state.chat.recentMessages);
  const moreRecentMessages = useSelector(
    (state) => state.chat.moreRecentMessages
  );

  const api = useApi();

  const loadRecentMessages = async () => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/recent-messages/`;
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data) {
      dispatch(chatActions.recentMessagesUpdated(response.data));
    }
    setLoading(false);
  };

  const loadMoreMessages = async () => {
    const url = recentMessages?.next
      ?.replace('http://', 'https://')
      ?.replace(BASE_API_URL, '');
    if (url) {
      const response = await api.get(url);
      if (response?.data) {
        const newMoreRecentMessages = [
          ...moreRecentMessages,
          ...response.data?.results,
        ];
        dispatch(chatActions.moreRecentMessagesUpdated(newMoreRecentMessages));
        dispatch(
          chatActions.recentMessagesUpdated({
            ...recentMessages,
            next: response.data?.next,
          })
        );
      }
    }
  };

  const handleShowMoreChatBoxBtnClick = () => {
    loadMoreMessages();
  };

  useEffect(() => {
    if (!recentMessages && !loading) {
      loadRecentMessages();
    }
  }, [recentMessages]);

  return { loading, handleShowMoreChatBoxBtnClick, loadRecentMessages };
}
