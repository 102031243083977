import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-toastify';

export default function useUpdateMentorSettings() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleUpdateSettings = async (
    mentor,
    data,
    notify = true,
    isForm = false
  ) => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentor?.name}/settings/`;
    const response = await api.put(endpoint, data, isForm);
    if (response?.data) {
      if (notify) {
        toast.success('Mentor has been updated!');
      }
      return response?.data;
    } else {
      if (notify) {
        toast.error(response?.error);
      }
    }
  };

  return { handleUpdateSettings };
}
