import React from 'react';
import './Llmsetapimodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useSetLLMCred from '../../hooks/llm/useSetLLMCred';
import Loader from '../../modules/loader';
import { convertFirstLetterUppercase, firstLetterUppercase } from '../../utils/shared';

const Llmsetapimodalbox = (props) => {
  const { handleModalCloseClick } = useTriggers();
  const edit = useSelector((state) => state.mentors.edit);
  const { saving, key, handleSaveKey, handleKeyChange } = useSetLLMCred();
  return (
    <div className="modal llm-set-api-key-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Set API key</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block llm-api-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form llm-api-key-form"
              data-name="Email Form 2"
              data-wf-element-id="96f03ad7-de27-d781-c3a7-8bc7a2f85ae1"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    {convertFirstLetterUppercase(edit?.llmProvider)} API key
                  </label>
                  <div className="required-field-label">
                    This field is required
                  </div>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  value={key}
                  disabled={saving}
                  onChange={handleKeyChange}
                  placeholder={`${convertFirstLetterUppercase(edit?.llmProvider)} API key`}
                  type="text"
                />
              </div>
              <div className="w-layout-hflex flex-block-20">
                {saving ? (
                  <Loader />
                ) : (
                  <a
                    className="default-btn form-submit-btn invite-user-submit-btn w-button"
                    data-w-id="96f03ad7-de27-d781-c3a7-8bc7a2f85aea"
                    href="#"
                    onClick={handleSaveKey}
                  >
                    Set
                  </a>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Llmsetapimodalbox;
